<!-- <div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3>{{ title }}</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="'/dashboard/default'">
                            <svg class="stroke-icon">
                <use href="assets/svg/icon-sprite.svg#stroke-home"></use>
              </svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
                    <li class="breadcrumb-item active">{{ active_item }}</li>
                </ol>
            </div>
        </div>
    </div>
</div> -->

<div style="height: 50px;"></div>