<div class="notification-box" (click)="toggleNotificationMobile()">
    <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
    <span class="badge rounded-pill badge-secondary">4</span>
</div>
<div class="onhover-show-div notification-dropdown">
    <h6 class="f-18 mb-0 dropdown-title">Notificaciones</h6>
    <ul>
        <li class="b-l-primary border-4">
            <p>Nuevo adelanto solicitado<span class="font-primary">2 días</span></p>
        </li>
        <!-- <li class="b-l-warning border-4">
      <p>Delivery Complete<span class="font-warning">6 hr</span></p>
    </li> -->
        <!-- <li><a class="f-w-700" href="#">Check all</a></li> -->
    </ul>
</div>